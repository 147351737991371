import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { CCAuthService } from '@heidelberg/control-center-frontend-integration/auth';

import { CCRoleId, DataLayer, DataLayerNull, ROUTE_PARAMS, TrafficType } from '@data-terminal/shared-models';
import { getParams } from '@data-terminal/utils';

declare global {
    interface Window {
        dataLayer: (DataLayer | DataLayerNull)[];
    }
}

const PLACEHOLDER = 'X';

const dataLayerNullObject = {
    event_data: null,
};

@Injectable({
    providedIn: 'root',
})
export class Ga4Service {
    private prevPageLocation: string | undefined;
    private hasConsent = false;

    private readonly pageViewBuffer: (DataLayer | DataLayerNull)[] = [];

    constructor(
        private readonly router: Router,
        private readonly activatedRoute: ActivatedRoute,
        private readonly ccAuthService: CCAuthService
    ) {}

    public pageView(): void {
        const personSession = this.ccAuthService.getCurrentUser();
        const snapshotParams = getParams(this.activatedRoute);
        const organizationMembership = this.ccAuthService.getCurrentMembership();
        let path = this.router.url;
        path = this.pageView_urlDeleteHash(path);
        path = this.pageView_urlDeleteOrgId(path, snapshotParams);
        path = this.pageView_urlExchangeMachineNumber(path, snapshotParams);
        path = this.pageView_urlExchangeOperationId(path, snapshotParams);

        const origin = window.location.origin;

        const dataLayerObject = {
            event: 'hcp.page_view',
            ga4_event_name: 'page_view',
            event_type: 'page_view',
            application: 'data capture',
            page_location: origin + path, // dynamic, insert the full URL, see below (string)
            page_path: path, //dynamic, everything from page_location after the domain (string)
            page_referrer: this.prevPageLocation ?? document.referrer,
            org_id: this.pageView_orgId() /* dynamic, insert the organization ID; if a user belongs to several organizations, append the information and separate with a comma and
            space (e.g., “a00a7a42-9a56-4df8-a49f-1f9c898a7b69, b00w4a31-3a57-4df8-a49f-1f9c898a7b69”) dynamic (string) */,
            ssu: this.pageView_ssus(
                organizationMembership?.organization.ssus || []
            ) /* dynamic, insert the user’s SSU; if a user belongs to several SSUs, append the information and separate with a comma and space(e.g., “S450, S150, S080”)
                (string) */,
            customer_id: this.pageView_hdmCustomerNumbers(
                organizationMembership?.organization.hdmCustomerNumbers || []
            ) /* dynamic, insert the user’s customer ID; if a user has several customer IDs, append the information and separate with a coma and space
                (e.g., “823472843, 271649744”) (string) */,
            user_id: personSession?.id ? personSession.id : undefined, // dynamic (string)
            user_language: personSession?.locale ? personSession.locale : undefined, // dynamic, keep this shorted format (string)
            user_country: personSession?.countryCode ? personSession.countryCode : undefined, // dynamic, keep this shorted format (string)
            user_role: this.pageView_mapRolesToReadable((organizationMembership?.ccRoles as CCRoleId[]) || []),
            traffic_type: this.pageView_trafficType(
                personSession?.email || ''
            ) /* dynamic, insert “internal” if user has @heidelberg.com or .ext@heidelberg.com in their mail address or if they have the postfix niceproject.eu;
                if not “external” (string) */,
        };
        if (this.hasConsent) {
            if (this.pageViewBuffer.length > 0) {
                this.drainPageViewBuffer();
            }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(dataLayerNullObject);
            window.dataLayer.push(dataLayerObject);
        } else {
            this.pageViewBuffer.push(dataLayerObject);
        }

        this.prevPageLocation = origin + path;
    }

    public setConsentTrue(): void {
        this.hasConsent = true;
        this.drainPageViewBuffer();
    }

    private drainPageViewBuffer(): void {
        while (this.pageViewBuffer.length > 0) {
            window.dataLayer = window.dataLayer || [];
            const entry = this.pageViewBuffer.shift();
            if (entry !== undefined && entry !== null) {
                window.dataLayer.push(dataLayerNullObject);
                window.dataLayer.push(entry);
            }
        }
    }

    private pageView_trafficType(email: string): TrafficType {
        if (email.includes('@heidelberg.com') || email.includes('niceproject.eu')) {
            return 'internal' as TrafficType;
        } else {
            return 'external' as TrafficType;
        }
    }

    private pageView_orgId(): string | undefined {
        const activeOrgId = this.ccAuthService.getCurrentMembership()?.organizationId;
        return activeOrgId && activeOrgId.length > 0 ? activeOrgId : undefined;
    }

    private pageView_urlDeleteHash(url: string): string {
        return url.replace('/#', '');
    }

    private pageView_urlDeleteOrgId(
        url: string,
        snapshotParams: {
            [key: string]: string;
        }
    ): string {
        if (url.includes(snapshotParams[ROUTE_PARAMS.orgId])) {
            return url.replace('/' + snapshotParams[ROUTE_PARAMS.orgId], '');
        }
        return url;
    }

    private pageView_urlExchangeMachineNumber(
        url: string,
        snapshotParams: {
            [key: string]: string;
        }
    ): string {
        if (url.includes(snapshotParams[ROUTE_PARAMS.machineId])) {
            return url.replace(snapshotParams[ROUTE_PARAMS.machineId], PLACEHOLDER);
        }
        return url;
    }

    private pageView_urlExchangeOperationId(
        url: string,
        snapshotParams: {
            [key: string]: string;
        }
    ): string {
        if (url.includes(snapshotParams[ROUTE_PARAMS.primaryKey])) {
            return url.replace(snapshotParams[ROUTE_PARAMS.primaryKey], PLACEHOLDER);
        }
        return url;
    }

    private pageView_hdmCustomerNumbers(hdmCustomerNumbers: string[]): string | undefined {
        const hdmCustomerNumbersValue = hdmCustomerNumbers.join(', ').trim();
        return hdmCustomerNumbers.length > 0 ? hdmCustomerNumbersValue : undefined;
    }

    private pageView_ssus(ssus: string[]): string | undefined {
        const ssusValue = ssus.join(', ').trim();
        return ssusValue.length > 0 ? ssusValue : undefined;
    }

    private pageView_mapRolesToReadable(ccRoles: CCRoleId[] | undefined): string | undefined {
        const roleMap: { [key in CCRoleId]: string } = {
            mdf: 'managing director finance',
            cfo: 'chief financial officer',
            buc: 'business controller',
            sar: 'sales representative',
            pur: 'purchaser',
            vpo: 'vice president operations',
            mam: 'maintenance manager',
            mao: 'maintenance operator',
            stm: 'store man',
            tra: 'trainee',
            adm: 'administrator',
            own: 'owner',
            mem: 'member',
            ppl: 'production planner',
            ppm: 'prepress manager',
            ppo: 'prepress operator',
            ppa: 'prepress assistant',
            cma: 'color manager',
            prm: 'pressroom manager',
            pro: 'press operator',
            pra: 'press assistant',
            pom: 'postpress manager',
            poo: 'postpress operator',
            poa: 'postpress assistant',
        };
        return ccRoles !== undefined && ccRoles.length > 0
            ? ccRoles
                  .map((role) => roleMap[role])
                  .join(', ')
                  .trim()
            : undefined;
    }
}
